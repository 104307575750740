export const menuArr = [
  {
    title: "All",
    query: "all",
  },
  {
    title: "Brand Film",
    query: "brand-film",
  },
  {
    title: "Commercial Film",
    query: "commercial-film",
  },
  {
    title: "Viral Video",
    query: "viral-video",
  },
  {
    title: "YouTube",
    query: "youtube",
  },
  {
    title: "Others",
    query: "others",
  },
];
